<template>
  <dialog-outline v-model="showDialog">
    {{ dialogTitle }}
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <template v-slot:pre-context>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!-- context -->
    <template v-slot:context>
      <!-- record details banner, which represents a subject that current operation will be performed -->
      <record-banner :record="currentRecord"></record-banner>

      <!-- Upload file input -->
      <file-input
        @change="onFileInputChange"
        accept=".xml"
        :progress-visible="visibleProgress"
        :progress-value="uploadFileProgress"
      >
        <template v-slot="{ progress }">
          <slot name="progressDisplay">
            {{ formatProgressText(progress.value) }}
          </slot>
        </template>
      </file-input>

      <div class="mt-5">
        <v-radio-group dense v-model="option">
          <v-radio :value="importAnnotationOptions.customPage">
            <template v-slot:label>
              {{ importSelectedLabel }}
              <div style="width: 200px">
                <v-select
                  class="ml-5"
                  v-model="selectedPage"
                  :items="totalPages"
                  :label="labelPage"
                  :prepend-icon="iconPage"
                ></v-select>
              </div>
            </template>
          </v-radio>
          <v-radio
            :label="importAllLabel"
            :value="importAnnotationOptions.allPages"
          ></v-radio>
        </v-radio-group>
      </div>
    </template>

    <!-- Dialog Actions -->
    <template v-slot:actions>
      <base-button @click="onOk" :disabled="!enableCommandUpload">
        {{ dialogOkCommandLabel }}
      </base-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// design
import { iconImport, iconPage } from "@/design/icon/iconConst";

// model
import {
  findImageOperation,
  imageOperation,
  importAnnotationOptions
} from "@/model/image/imageModel";

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { baseComponentMixin } from "@/mixins/shared/base/component/baseComponentMixin";
import { recordBannerAble } from "@/mixins/shared/bannerAble/recordBannerAble";

//model
import { actionResultType } from "@/model/action/actionModel";
import {
  fullRecordName,
  getImportedFileExtension
} from "@/services/record/recordService";
import { toLowerCaseSafe } from "@/utils";

export default {
  name: "ImportAnnotationDialog",
  mixins: [dialogOutlineMixin, baseComponentMixin, recordBannerAble],
  components: {
    FileInput: () => import("@/components/shared/core/inputs/FileInput")
  },
  props: {
    /**
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
     */
    currentRecord: undefined,
    totalPages: {
      type: Array,
      required: true
    },
    progress: {
      type: Number,
      required: true
    },
    importEvent: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      uploadFileInput: undefined,
      iconImport: iconImport,
      iconPage: iconPage,
      option: importAnnotationOptions.customPage,
      selectedPage: 1,
      importAnnotationOptions: importAnnotationOptions
    };
  },

  computed: {
    /**
     * Import Annotation Operation
     * @return {{name: string, icon: string, label: string}} Import Annotation Operation
     */
    operation() {
      return findImageOperation(imageOperation.ImportAnnotation);
    },

    /**
     * Import Annotation operation Label
     * @return {string} Import Annotation operation Label
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * Get Import Annotation Dialog title
     * @return {string} Move Page Dialog title
     */
    dialogTitle() {
      return `${this.operationLabel}`;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return "Import";
    },

    /**
     * label Select Page
     * @return {string} label Select Page
     */
    labelPage() {
      return "Select Page";
    },

    /**
     * Get Upload File Progress
     * @return {Number}
     */
    uploadFileProgress() {
      return this.progress;
    },

    /**
     * determine whether Progress should be visible
     * @return {boolean}
     */
    visibleProgress() {
      return this.progress > 0;
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel} ${
        this.currentRecord?.recordType
      }: '${fullRecordName(this.currentRecord)}'`;
    },

    /**
     * compute whether Command Upload is enabled
     * @return {boolean} true if Command Upload is enabled
     */
    enableCommandUpload() {
      return this.uploadFileInput !== undefined;
    },

    /**
     * import All Label
     * @return {string}
     */
    importAllLabel() {
      return "Import On All Pages";
    },

    /**
     * import Selected Label
     * @return {string}
     */
    importSelectedLabel() {
      return "Import On";
    }
  },

  methods: {
    /**
     * Performs Import Annotation
     */
    async onOk() {
      try {
        this.clearAlert();
        const pageOption = this.getImportPageOption();
        const result = await this.importEvent(pageOption, this.uploadFileInput);
        if (result?.type === actionResultType.success) {
          await this.autoCloseDialog(result.message);
        } else {
          this.internalAlert = this.createAlertError(
            this.formatAlertError(this.action, result.message),
            false
          );
        }
      } catch (e) {
        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
      } finally {
        this.uploadFileInput = undefined;
      }
    },

    getImportPageOption() {
      return this.option === importAnnotationOptions.customPage
        ? this.selectedPage
        : 0;
    },

    /**
     * On File Input Change Event handler
     * @param file
     */
    onFileInputChange(file) {
      try {
        this.uploadFileInput = undefined;
        const ext = toLowerCaseSafe(getImportedFileExtension(file));
        console.log(`${this.$options.name} onFileInputChange() ext:`, ext);
        if (!ext) {
          this.clearAlert();
        } else {
          this.uploadFileInput = file;
          this.clearAlert();
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * format Progress Text
     * @param {Number|number} progress
     * @return {string}
     */
    formatProgressText(progress) {
      return `${progress} %`;
    }
  }
};
</script>
